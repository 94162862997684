import React from 'react'
import { CategoryType, CategoryProductType, FilterType } from "types"
import Layout from "../components/Layout/Layout"
import SEO from "../components/seo"
import Category from '../components/Category/Category'

type CategoryTemplateProductsType = {
  pageContext: {
    category: CategoryType
    filters: FilterType[]
    categoryProducts: CategoryProductType[]
    lastmoddate: String
  }
  location: Location
}

const categoryTemplateProducts: React.FC<CategoryTemplateProductsType> = ({
  pageContext: { category, categoryProducts, filters, lastmoddate },
  location,
}) => {
  var metaTitle = ""
  var metaDescription = ""
  var metaKeywords = ""

  category.seoInfo.custom_attributes.forEach(x => {
    switch (x.attribute_code) {
      case "meta_title": {
        metaTitle = x.value
        break
      }
      case "meta_description": {
        metaDescription = x.value
        break
      }
      case "meta_keywords": {
        metaKeywords = x.value
        break
      }
      default: {
        break
      }
    }
  })
  return (
    <Layout>
      <SEO
        title={metaTitle}
        description={metaDescription}
        keywords={metaKeywords}
      />
      <Category category={category} categoryProducts={categoryProducts} filters={filters} />
    </Layout>
  )
}

export default categoryTemplateProducts