import React from 'react'
import CloseRoundedIcon from '@material-ui/icons/CloseRounded'

type selectedFiltersType = {
   selectedFilters: any[]
   removeFilterOnly: (filterAttribute: string, filterValue: string) => void
   removeAllFilters: () => void
}

const selectedFilters: React.FC<selectedFiltersType> = ({ selectedFilters, removeFilterOnly, removeAllFilters }) => {
   return (
      <div>
         <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {selectedFilters.map((filtro, index) => {
               return (

                  <div
                     key={index}
                     style={{ backgroundColor: '#fff', padding: '6px 10px', borderRadius: '5px', margin: '5px 5px 0 0', boxShadow: '0px 3px 3px 0px rgba(89, 89, 89, 0.1)', cursor: 'default', width: 'fit-content', display: 'flex' }}
                  >
                     <span style={{ marginRight: '3px', fontSize: '13px' }}>{filtro.attributeLabel} </span>
                     <span style={{ cursor: 'pointer' }} onClick={() => removeFilterOnly(filtro.attribute, filtro.value)}>
                        <CloseRoundedIcon style={{ maxHeight: '17px', maxWidth: '17px', display: 'flex', alignItems: 'center' }} />
                     </span>
                  </div>
               )
            })}
         </div>
         {selectedFilters.length > 0 && <div style={{ marginTop: '10px', marginLeft: '3px', cursor: 'pointer' }}><span onClick={removeAllFilters} style={{ color: '#e8927c' }}>LIMPAR FILTROS</span></div>}
      </div>
   )
}

export default selectedFilters
