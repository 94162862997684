import React from 'react'
import styles from '../Category.module.scss'
import { Link } from "gatsby"
import KeyboardArrowRightRoundedIcon from '@material-ui/icons/KeyboardArrowRightRounded';
import ClassNames from 'classnames/bind'

let cx = ClassNames.bind(styles)

type breadcrumbItem = {
   name: string
   slug: string
}

type breadcrumbType = {
   breadcrumbItems: breadcrumbItem[]
}

const breadcrumb: React.FC<breadcrumbType> = ({ breadcrumbItems }) => {

   return (
      <div className={styles.divBreadcrumb}>
         {breadcrumbItems.map((breadcrumbItem: breadcrumbItem, idx: number) => {
            const className = cx({
               breadcrumb: true,
               lastBreadcrumb: idx === breadcrumbItems.length - 1
            });
            return (
               <div key={idx} className={styles.breadcrumbInlineBlock}>
                  <div className={className}>
                     <Link
                        to={idx === 0 ? breadcrumbItem.slug : '/' + breadcrumbItem.slug + '/'}
                        className={styles.linkBreadcrumb} >
                        {breadcrumbItem.name}
                     </Link>
                     {
                        idx !== breadcrumbItems.length - 1 && <KeyboardArrowRightRoundedIcon />
                     }
                  </div>
               </div>
            )
         })}
      </div>
   )
}

export default breadcrumb
