import React, { useState } from 'react'
import styles from '../Category.module.scss'
import ImportExportIcon from '@material-ui/icons/ImportExport'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import CropOriginalIcon from '@material-ui/icons/CropOriginal'
import ListItem from "@material-ui/core/ListItem"
import List from "@material-ui/core/List"
import Button from "@material-ui/core/Button"

type panelType = {
   displayMode: string
   toggleDisplay: (display: string) => void
   setOrder: (nome: string) => void
   productQty: number
}

const panel: React.FC<panelType> = ({ displayMode, toggleDisplay, setOrder, productQty }) => {
   const [expanded, setExpanded] = useState(false);
   
   const toggleChange = () => {
      setExpanded(!expanded);
   };

   return (
            <div style={{ display: 'flex', borderBottom: '3px solid #f8f7f6' }}>

               <div style={{ backgroundColor: '#fff', width: '75%', padding: '10px 20px', borderRight: '3px solid #f8f7f6', textTransform: 'uppercase' }}>
                  x produtos encontrados
               </div>

               <Button
                  style={{ backgroundColor: '#fff', width: '12.5%', padding: '10px', borderRight: '3px solid #f8f7f6', borderRadius: 0, textTransform: 'capitalize', display: 'flex', justifyContent: 'space-evenly' }}
                  onClick={() => toggleDisplay(displayMode)}
               >
                  <CropOriginalIcon />{displayMode}
               </Button>

               <div style={{ width: '12.5%' }}>

                  <div style={{ backgroundColor: '#fff', padding: '10px', display: 'flex', justifyContent: 'space-around', alignItems: 'center', cursor: 'pointer' }} onClick={() => toggleChange()}>
                     <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <ImportExportIcon />Ordenar
                  </div>
                     <KeyboardArrowDownIcon fontSize='small' />
                  </div>

                  <div style={(expanded) ? { position: 'absolute', padding: '0px', width: '9.9%', backgroundColor: 'white', boxShadow: '0px 3px 3px 0px rgba(89, 89, 89, 0.1)' } : { display: 'none' }} >
                     <List style={{ backgroundColor: 'grey', zIndex: 9999 }}>
                        <ListItem
                           button
                           id="default"
                           onClick={(e) => { setOrder(e.target.id); toggleChange() }}
                           className={styles.listItemOrder}
                        >
                           Mais relevantes
                        </ListItem>
                        <ListItem
                           button
                           id="precoMaior"
                           onClick={(e) => { setOrder(e.target.id); toggleChange() }}
                           className={styles.listItemOrder}
                        >
                           Maior Preço
                        </ListItem>
                        <ListItem
                           button
                           id="precoMenor"
                           onClick={(e) => { setOrder(e.target.id); toggleChange() }}
                           className={styles.listItemOrder}
                        >
                           Menor Preço
                        </ListItem>
                     </List>
                  </div>

               </div>
            </div>
   )
}

export default panel
