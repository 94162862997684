import React, { useState, useEffect, useCallback } from 'react'
import DisplayStyle from './DisplayMode.module.scss'
import ClassNames from 'classnames/bind'
import { useInView } from "react-intersection-observer"
import axios from "axios"
import { MICROSERVICE_GET_CATEGORY_PRODUCTS, SOCKET_NODES } from "../../../userglobals"
import io from "socket.io-client"
import {
   Breadcrumb,
   Filters,
   Panel,
   ProductCard
} from './components'

let socket: SocketIOClient.Socket;
let cx = ClassNames.bind(DisplayStyle);


type CategoryTypeProps = {
   category: any
   categoryProducts: any
   filters: any
}


const Category: React.FC<CategoryTypeProps> = ({ category, categoryProducts, filters }) => {
   
   const [displayMode, setDisplayMode] = useState('galeria')
   const [categoryState, setCategoryState] = useState<{
      page: number
      loading: boolean
      error: boolean
      isCategoryFilterDrawerOpen: boolean
      isCategoryOrderDrawerOpen: boolean
      productsCategory: any
      filters: any
      isMounted: boolean
      maxPages: number
      visibleProducts: any
      selectedFilters: any
      visualFilters: any
      productsLoading: boolean
      order: string
   }>({
      page: 1,
      loading: false,
      error: false,
      isCategoryFilterDrawerOpen: false,
      isCategoryOrderDrawerOpen: false,
      productsCategory: categoryProducts,
      filters: filters,
      isMounted: false,
      maxPages: category.numberOfPages,
      visibleProducts: categoryProducts,
      selectedFilters: [],
      visualFilters: [],
      productsLoading: false,
      order: "default"
   })
   const [ref, inView, entry] = useInView()

   const emitJoin = useCallback(() => {
      socket.emit(
         "join",
         { categoryId: category.id })
   }, [category.id])

   useEffect(() => {
      //socket = io(MICROSERVICE_GET_CATEGORY_PRODUCTS);
      socket = io(SOCKET_NODES, { transports: ['websocket'] });
      emitJoin();
      console.log()
      setCategoryState(prevState => ({
         ...prevState,
         isMounted: true,
      }));
   }, [])

   useEffect(() => {
      if (inView === true && categoryState.page < categoryState.maxPages) {
         setCategoryState(prevState => ({
            ...prevState,
            loading: true,
            error: false,
         }));
         console.log('Emit Get Page');
         socket.emit('get-page', { categoryId: category.id, filters: categoryState.selectedFilters, page: categoryState.page + 1, order: categoryState.order }, (products: { page: ConcatArray<any>, numberOfPages: number }) => {
            console.log(products)
            setCategoryState(prevState => ({
               ...prevState,
               page: categoryState.page + 1,
               loading: false,
               visibleProducts: categoryState.visibleProducts.concat(
                  products?.page
               ),
               maxPages: products.numberOfPages
            }))

         });

      }
   }, [inView])

   function toggleDisplay(display: string) {
      let newDisplay = "";
      if (display === 'galeria') {
         newDisplay = 'lista';
      }
      if (display === 'lista') {
         newDisplay = 'galeria';
      }
      setDisplayMode(newDisplay)
   }

   function removeAllFilters() {
      setCategoryState(prevState => ({
         ...prevState,
         selectedFilters: [],
         visibleProducts: categoryProducts,
      }))
   }

   function removeFilterOnly(filterAttribute: string, filterValue: string) {
      const copySelectedFilters = [...categoryState.selectedFilters];
      const filterIndex = categoryState.selectedFilters.findIndex((x: { attribute: string; value: string }) => x.attribute === filterAttribute && x.value === filterValue);
      copySelectedFilters.splice(filterIndex, 1);

      setCategoryState(prevState => ({
         ...prevState,
         selectedFilters: copySelectedFilters,
         visualFilters: copySelectedFilters
      }))
      getProductsFiltered(copySelectedFilters, categoryState.order);
   }

   const getProductsFiltered = (filters: any, order: string) => {
      setCategoryState(prevState => ({
         ...prevState,
         productsLoading: true
      }))

      axios.post(MICROSERVICE_GET_CATEGORY_PRODUCTS + "push-redis/", { categoryId: category.id, filters: filters, page: 1, order: order })
         .then(responseAA => {
            //console.log(responseAA.data)
            setCategoryState(prevState => ({
               ...prevState,
               visibleProducts: responseAA.data.page,
               page: 1,
               maxPages: responseAA.data.numberOfPages,
               productsLoading: false
            }))
            if (window.scrollY === 0) {
               window.scrollTo(0, 1);
            } else {
               window.scrollTo(0, 0);
            }
         })
   }

   const setOrderDesk = (nome: string) => {

      setCategoryState(prevState => ({
         ...prevState,
         order: nome
      }))
      getProductsFiltered(categoryState.selectedFilters, nome);
   }

   function handleFilter(filterAttribute: string, filterValue: string, filterLabel: string, attributeLabel: string) {

      //console.log(filterAttribute, filterValue, filterLabel, attributeLabel);
      const copySelectedFilters = [...categoryState.selectedFilters];

      if (categoryState.selectedFilters.find((x: { attribute: string; value: string }) => x.attribute === filterAttribute && x.value === filterValue)) {
         // se filterAttribute e filter value já está em selectedFilters
         //  então remova o filtro
         // console.log('remove um filtro');
         const filterIndex = categoryState.selectedFilters.findIndex((x: { attribute: string; value: string }) => x.attribute === filterAttribute && x.value === filterValue);
         copySelectedFilters.splice(filterIndex, 1);

      } else if (categoryState.selectedFilters.find((x: { attribute: string; value: string }) => x.attribute === filterAttribute && x.value !== filterValue)) {
         // se filterAttribute já está em selectedFilters mas filter value não
         // 
         // console.log('modifica um filtro');
         const filterIndex = categoryState.selectedFilters.findIndex((x: { attribute: string; value: string }) => x.attribute === filterAttribute && x.value !== filterValue);

         /* MODIFICAR TODAS AS PROPRIEDADES */
         copySelectedFilters[filterIndex].value = filterValue;
         copySelectedFilters[filterIndex].attributeLabel = attributeLabel;
         copySelectedFilters[filterIndex].filterLabel = filterLabel;

      } else {
         // console.log('adiciona um filtro');
         copySelectedFilters.push({ attribute: filterAttribute, value: filterValue, filterLabel: filterLabel, attributeLabel: attributeLabel })
      }

      setCategoryState(prevState => ({
         ...prevState,
         selectedFilters: copySelectedFilters,
      }))
      getProductsFiltered(copySelectedFilters, categoryState.order);

   }
   console.log(categoryState.visibleProducts)
   let idx = 0;
   return (
      <div>
         <Breadcrumb breadcrumbItems={category.breadCrumbItems} />
         <div style={{ display: 'flex' }}>
            <Filters filters={filters} handleFilter={handleFilter} removeFilterOnly={removeFilterOnly} removeAllFilters={removeAllFilters} selectedFilters={categoryState.selectedFilters} />
            <div style={{ width: '100%' }}>
               <Panel displayMode={displayMode} toggleDisplay={toggleDisplay} setOrder={setOrderDesk} productQty={categoryState.maxPages * 10} />
               <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {
                     categoryState.visibleProducts.map((product: any, index: number) => {
                        idx = idx + 1;
                        const ClassName = cx({
                           cardGaleria: idx % 4 !== 0 && displayMode === 'galeria',
                           lastCardGaleria: idx % 4 === 0 && displayMode === 'galeria',
                           cardLista: displayMode === 'lista',
                        });

                        if (categoryState.visibleProducts.length === index + 1) {
                           return (
                              <div ref={ref} key={index} className={ClassName}>
                                 <ProductCard product={product} displayMode={displayMode} />
                              </div>
                           )
                        } else {
                           return (
                              <div key={index} className={ClassName}>
                                 <ProductCard product={product} displayMode={displayMode} />
                              </div>
                           )
                        }
                     })
                  }
               </div>
            </div>
         </div>
      </div>
   )
}

export default Category
