import React from 'react'
import styles from '../Category.module.scss'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import SelectedFilters from './selectedFilters'

type filtersType = {
   filters: any
   handleFilter: (filterAttribute: string, filterValue: string, filterLabel: string, attributeLabel: string) => void
   removeFilterOnly: (filterAttribute: string, filterValue: string) => void
   removeAllFilters: () => void
   selectedFilters: any[]
}

const filters: React.FC<filtersType> = ({ filters, handleFilter, removeFilterOnly, removeAllFilters, selectedFilters }) => {

   return (
      <div style={{ width: '25%', borderRight: '3px solid #f8f7f6' }}>
         <SelectedFilters selectedFilters={selectedFilters} removeFilterOnly={removeFilterOnly} removeAllFilters={removeAllFilters} />
         <div>
            {filters.filtros.map((filtro: any, idx: number) => {
               return (
                  <div key={idx} style={{ backgroundColor: '#fff', marginBottom: '6px', boxShadow: '0px 3px 3px 0px rgba(89, 89, 89, 0.1)' }}>
                     <div style={{ padding: '10px', backgroundColor: '#fbece8', textTransform: 'uppercase', color: '#e8927c', display: 'flex', justifyContent: 'space-between', alignItems: 'center', letterSpacing: '0.2px', fontSize: '13px' }}>
                        {filtro.nomeFiltro}
                        <ExpandMoreRoundedIcon />
                     </div>
                     <div>
                        <List>
                           {filtro.options.map((filterValue: any, idx: number) => {
                              return (
                                 <ListItem
                                    key={idx}
                                    button
                                    className={styles.listItem}
                                    onClick={() => { handleFilter(filtro.atributoFiltro, filterValue.value, filtro.defaultFrontendLabel, filterValue.label) }}
                                 >
                                    {filterValue.label}
                                 </ListItem>
                              )
                           })}
                        </List>
                     </div>
                  </div>
               )
            })}
         </div>
      </div>
   )
}

export default filters
